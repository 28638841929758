<template>
	<div>
		<div class="container">
			<div class="form-box">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 积分所属工会 -->
					<el-form-item label="所属单位" prop="unitAbbreviation">
						<el-input v-model="form.unitAbbreviation" readonly size="large" placeholder="请选择所属单位"
							@click="multiple"></el-input>
						<!--  <el-select @focus="closeIt" class="hunt" v-model="form.unitAbbreviation" placeholder="请选择所属单位" size="large"
		  	@click="multiple" clearable @change="numerical" /> -->
					</el-form-item>
					<!-- 订单号 -->
					<el-form-item label="订单号" prop="orderNumber">
						<el-input v-model="form.orderNumber" placeholder="请填写订单号" size="large"></el-input>
					</el-form-item>
					<!-- 返还积分 -->
					<el-form-item label="返还积分" prop="integralValue">
						<el-input v-model="form.integralValue" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
							size="large" placeholder="请填写返还积分"></el-input>
					</el-form-item>
					<!-- 备注 -->
					<el-form-item label="备注" prop="reason">
						<el-input v-model="form.reason" size="large" type="textarea" placeholder="请填写备注"></el-input>
					</el-form-item>
					<!-- 提示 -->
					<div class="hint">
						<div class="remind">提示：</div>
						<div>
							<div class="become"> 本操作不可撤销，请谨慎操作。</div>
						</div>
					</div>

					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</div>
		<!-- 积分分配工会选择 -->
		<!-- <div>
      <s3-layer v-model="visible1" title="所属单位" :shadeClose="true" :area="['500px', '80vh']" @yes="yes1">
          <el-tree
            :data="texture1"
            :props="defaultProps1"
            accordion 
            @node-click="dendrogram1"
          />
        </s3-layer>
    </div> -->
		<!-- 积分所属工会选择 -->
		<!-- <div>
      <s3-layer v-model="visible" title="所属单位" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
          <el-tree
            :data="texture2"
            :props="defaultProps"
            accordion
            @node-click="dendrogram"
          />
        </s3-layer>
    </div> -->
		<div>
			<s3-layer v-model="visible" title="所属单位" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />

				<!-- <div v-for="(item,index) in treeData" :key="index" @click="dendrogram(item)">
	  {{item.unitName}}
	</div> -->
			</s3-layer>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted
	} from "vue";
	// import { Zzlist,Addlist} from '../../utils/api'
	import {
		Zzlist,
		Zzzlist,
		ZNentry,
		ListPoints,
		ZClist
	} from '../../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {useStore} from "vuex";
	export default {
		name: "returnPoints",
		setup() {
			const route = useRoute();
			const options = ref('')
			const identifierS = ref('')
			// 积分分配工会弹窗
			let dendrogram1 = (node) => {

				console.log(node, "---node");
				options.value = node
				form.coding = options.value.unionName
			}
			// const defaultProps1 = {
			//     children: 'unionDtoList',
			//     label: 'unionName',
			//  }
			//  积分分配工会
			// let texture1 =ref([])
			// 积分所属工会
			let texture = ref([])
			const defaultProps = {
				label: 'unitName',
			}
			const yes = () => {
				form.coding = options.value.unionName
				visible.value = false;
			};



			const visible1 = ref(false);
			// 积分所属工会弹窗
			let dendrogram = (node) => {
				console.log(node)
				identifierS.value = node
				form.unitAbbreviation = identifierS.value.unitName
			}
			// const defaultProps = {
			//     // children: 'unionDtoList',
			//     label: 'unionName',
			//  }
			let treeData = ref([])
			const visible = ref(false);
			// 
			const yes1 = () => {
				// form.identifier=identifier.value.unitName
				visible.value = false;
			};
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					unitAbbreviation.value = ''
				}
			}

			onMounted(() => {
				// messge()
				messges()
			})
			// 获取组织列表信息
			// const messge=()=>{
			//   ZClist().then((res)=>{
			//     console.log(res,"444444")
			//     texture1.value=res.data.data.unionList
			//   })
			// }

			const messges = () => {

				ZClist().then((res) => {
					console.log(res, "444444")
					texture.value = res.data.data.unitList
				})

			}


			// 获取工会code
			let gaincode = ref('')
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				coding: "",
				identifier: "",
				allocate: "",
				remark: ""
			})
			// 表单提交
			const router = useRouter();
			const loa = ref(false)
			// 关闭当前页
			const store = useStore();
			const expression = ref([])
			const current = ref('')
			const Indexs = ref(null)



			const onSubmit = () => {
				console.log("要提交了")
				loa.value = true
				// 表单校验
				ruleFormRef.value.validate((valid) => {

					if (valid == true) {
						let data = {
							// allocationUnionAbbreviation:options.value.unionAbbreviation,
							// allocationUnionName:options.value.unionName,
							// allocationUnionCode:options.value.unionCode,
							unitAbbreviation: identifierS.value.unitAbbreviation,
							// belongUnionName:identifierS.value.unionName,
							// belongUnionCode:identifierS.value.unionCode,
							orderNumber: form.orderNumber,
							integralValue: form.integralValue,
							reason: form.reason
						}
						console.log(data)


						ListPoints(qs.parse(data)).then((res) => {
							if (res.data.code == 0) {
								ElMessage({
									message: "提交成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value=route.path
									expression.value=store.state.tagsList
									
									expression.value.forEach((item,index)=>{
									  if(current.value==item.path){
									    Indexs.value=index
									  }
									})
									store.state.tagsList.splice(Indexs.value,1)
									loa.value = false
									// 关闭当前页
									router.push({
										name: 'rewardPoints',
										params: {
											userId: 1
										}
									});
								}, 500)
							} else{
							  ElMessage({
							   message: res.data.msg,
							    type: "error",
							    center: true,
							  });
							 loa.value = false
							}
						})
					} 
				})
			}
			// 验证表单提示
			const rules = {
				unitAbbreviation: [{
					required: true,
					message: "请选择所属单位",
					trigger: "change"
				}, ],
				orderNumber: [{
					required: true,
					message: "请填写订单号",
					trigger: "change"
				}, ],
				integralValue: [{
					required: true,
					message: "请填写返还积分值",
					trigger: "change"
				}, ],
				reason: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ]
			}
			return {
				texture,
				// 积分分配工会
				dendrogram1,
				visible1,
				yes1,
				// 选择积分所属工会
				dendrogram,
				defaultProps,
				treeData,
				visible,
				yes,
				// 提交按钮
				loa,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules,
				multiple,
				numerical,
			}
		},
	}
</script>

<style lang='scss' scoped>
	.become {
		color: red;
	}

	.remind {
		/* width: 65px; */
		color: red;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
	}

	.hint {
		text-align: justify;
		display: flex;
		font-size: 14px;
		margin-left: 75px;
		/* margin-top: -10px; */
		margin-bottom: 20px;
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep .el-form-item__label {
		font-weight: bold;
		width: 115px;
	}

	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .submit {
		margin-left: 110px;
	}

	::v-deep .el-input {
		height: 44px;
	}

	::v-deep .el-form-item {
		margin-bottom: 30px;
		align-items: center;
	}

	::v-deep .el-tree-node__content {
		font-weight: 500;
	}

	::v-deep .el-select-dropdown__item.hover {
		background-color: #ffffff;
	}

	::v-deep .el-select-dropdown__wrap {
		background-color: #ffffff;
	}

	::v-deep .form-box {
		width: 500px;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .container {
		max-width: 91%;
		margin: auto;
		margin-top: 10px;
		padding: 30px;
		background: #fff;
		border: 1px solid #ddd;
		border-radius: 5px;
	}
</style>